import { FC, useEffect, useState, useContext } from "react";
import { CircularProgress, Divider } from "@mui/material";
import { PermissionType } from "@emovid/payloads-library";
import { CanCreatePrivateThreadForPost, formattedTimeSince } from "../../Common/Helper";
import { displayNameForUser } from "../../Util/UserInfoMap";
import { IPostResponseAttributes } from "../../Interfaces/IPostResponseAttributes";
import { PostsListProps } from "./IPostsListProps";
import { AuthContext } from "../../Contexts/AuthContext";
import { getTotalViews } from "./ViewEmovid";
import ParentResponsesList from "./ParentResponsesList";
import { START_PRIVATE_THREAD_BUTTON_TEXT } from "../../Common/Constants";

export const MobilePostsList: FC<PostsListProps> = ({
    event,
    eventPosts,
    isCreatorLoggedIn,
    selectedPost,
    onPostSelected,
    privacySettings,
    onViewsClickedForPost,
    onNewThreadClickedForPost,
    parentPostId,
    hideCreatorLabel
}: PostsListProps) => {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        setTimeout(() => {
            let selectedPostElement = document.getElementById(selectedPost.id || "");
            if (selectedPostElement) selectedPostElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }, 300);
    }, [selectedPost]);

    const handleViewsClickedForPost = (e: any, post: IPostResponseAttributes) => {
        e.stopPropagation();
        e.preventDefault();
        onViewsClickedForPost && onViewsClickedForPost(post);
    };

    const handleNewThreadClickedForPost = (e: any, post: IPostResponseAttributes) => {
        e.stopPropagation();
        e.preventDefault();
        onNewThreadClickedForPost && onNewThreadClickedForPost(post);
    };

    const canCreatePrivateThread = (post: IPostResponseAttributes): boolean => {
        return CanCreatePrivateThreadForPost(event, post, authContext, privacySettings?.keep_replies_private || false);
    };

    return (
        <>
            {(eventPosts || []).map((post: IPostResponseAttributes, i: number) => {
                return (
                    <div key={i} className="p-2 mb-2 video-preview d-flex" id={post.id} onClick={() => onPostSelected(post)}>
                        <div
                            className={
                                "mobile-view-page-video-thumbnail" +
                                (selectedPost && post.id === selectedPost.id ? " selected " : "") +
                                (!post.thumbnail_url && !post.email_thumbnail_url ? " no-image " : "")
                            }
                            style={{ backgroundImage: `url(${post.thumbnail_url || post.email_thumbnail_url || "/images/email-play-overlay.png"})` }}
                        >
                            {isCreatorLoggedIn && post.permission_type === PermissionType.PRIVATE && (
                                <div className="position-absolute private-video">
                                    <i className="fa fa-eye"></i>&nbsp; Private Reply
                                </div>
                            )}
                            <div className="video-viewed-progress-container">
                                <div className="video-viewed-progress-bar" style={{ width: `${post.viewed_percent || 0}%` }}></div>
                            </div>
                        </div>
                        <div className="ps-3 pe-2 w-100">
                            <div>
                                {displayNameForUser(post.Creator!)}
                                {post.is_creator && !parentPostId && !hideCreatorLabel ? " (Creator) " : ""}
                            </div>
                            <div className="text-right font-light-color">
                                <span>{formattedTimeSince(post.sent_at || "")}</span>
                            </div>
                            <div className="text-right">
                                {authContext.user?.userId === post.creator_id && (
                                    <>
                                        {post && !post.views_loaded && (
                                            <span>
                                                <CircularProgress size="0.7rem" className="me-2" />
                                                Fetching views...&nbsp;
                                            </span>
                                        )}
                                        {post && post.views_loaded && (
                                            <button className="btn btn-link font-light-color p-0 border-0" onClick={e => handleViewsClickedForPost(e, post)}>
                                                <u>{getTotalViews(authContext.user?.userId || "", post)}</u>
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                            {canCreatePrivateThread(post) && (
                                <div className="w-100 d-flex">
                                    <button
                                        type="button"
                                        className="btn btn-outline-light mt-1 ms-auto"
                                        style={{ fontSize: "0.9rem" }}
                                        onClick={e => handleNewThreadClickedForPost(e, post)}
                                    >
                                        {START_PRIVATE_THREAD_BUTTON_TEXT}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

const MobileResponsesList: FC<PostsListProps> = ({
    event,
    eventPosts,
    isCreatorLoggedIn,
    selectedPost,
    onPostSelected,
    privacySettings,
    onViewsClickedForPost,
    onNewThreadClickedForPost,
    isPreviewMode,
    parentPostId
}: PostsListProps) => {
    const [creatorPosts, setCreatorPosts] = useState<IPostResponseAttributes[]>([]);
    const [recipientPosts, setRecipientPosts] = useState<IPostResponseAttributes[]>([]);

    useEffect(() => {
        let creatorPostsArr: IPostResponseAttributes[] = [];
        let recipientPostsArr: IPostResponseAttributes[] = [];
        eventPosts.forEach((post: IPostResponseAttributes) => {
            if (isPreviewMode) {
                if (post.id === selectedPost.id) recipientPostsArr.push(post);
            } else {
                if (post.is_creator) creatorPostsArr.push(post);
                else recipientPostsArr.push(post);
            }
        });
        setCreatorPosts(creatorPostsArr);
        setRecipientPosts(recipientPostsArr);
    }, [eventPosts]);

    return (
        <div className="response-previews-container">
            {!isPreviewMode && parentPostId && (
                <ParentResponsesList
                    event={event}
                    parentPostId={parentPostId}
                    selectedPost={selectedPost}
                    onPostSelected={onPostSelected}
                    isCreatorLoggedIn={isCreatorLoggedIn}
                    onViewsClickedForPost={onViewsClickedForPost}
                    onNewThreadClickedForPost={onNewThreadClickedForPost}
                />
            )}
            <MobilePostsList
                event={event}
                parentPostId={parentPostId}
                eventPosts={creatorPosts}
                isCreatorLoggedIn={isCreatorLoggedIn}
                selectedPost={selectedPost}
                privacySettings={privacySettings}
                onPostSelected={onPostSelected}
                onViewsClickedForPost={onViewsClickedForPost}
                onNewThreadClickedForPost={onNewThreadClickedForPost}
            />
            {!parentPostId && !isPreviewMode && <Divider className="mt-2 text-white">Responses</Divider>}
            <MobilePostsList
                event={event}
                parentPostId={parentPostId}
                eventPosts={recipientPosts}
                isCreatorLoggedIn={isCreatorLoggedIn}
                selectedPost={selectedPost}
                privacySettings={privacySettings}
                onPostSelected={onPostSelected}
                onViewsClickedForPost={onViewsClickedForPost}
                onNewThreadClickedForPost={onNewThreadClickedForPost}
            />
            {recipientPosts.length < 1 && !parentPostId && <div className="p-4 text-center">No responses yet!</div>}
        </div>
    );
};

export default MobileResponsesList;
